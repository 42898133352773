.closeWrapp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.closeBtn {
  font-size: 22px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

/* title */
.titleWrapp {
  width: 587px;
  display: flex;
}

.titleBtnWrapp {
}

.titleQuestion {
  font-size: 36px;
}

.titleBtn {
  width: 96px;
  height: 37px;
  background-color: #14142b;
  border: 1px solid #cbffae;
  border-radius: 12px;
  color: #cbffae;
  font-weight: 700;
}
.titleMain {
  padding-left: 15px;
}

/* below title */
.underTitleParagraphWrapp {
  width: 587px;
}
.subParaTitleBelow {
  font-size: 15px;
  font-weight: 400;
  font-family: 'Poppins';
}
