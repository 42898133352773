.mainQuestionWrapp {
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 500px;
  overflow: auto;
}

.buttonsWrapp {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
