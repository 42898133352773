.quttonWrapp {
  width: 587px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.qutton {
  width: 147px;
  height: 68px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #14142b;
  border: 1px solid #14142b;
}

.quttonTxt {
  font-size: 18px;
  margin-left: 10px;
}

.quttonSign {
  margin-top: 3px;
  font-size: 32px;
}
