.tmWrapp {
  width: 99%;
  background-color: #262338;
  border-radius: 16px;
  margin-left: 0px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 0;
}

.wrapperSingle {
  width: 95%;
  display: flex;
  align-items: center;
  overflow: auto;
  /* padding: 5px 4px; */
  justify-content: center;
  margin-right: 14px;
}

.titleTm {
  width:100%;
}

.title{
  font-weight: 600;
  font-family: 'Poppins';
  font-size: 22px;
  margin-left: 30px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #797878;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #14142b;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #12111a;
}

/* SINGLE TESTIMONIAL */
.singleTmWrapp {
  /* min-width: 350px; */
  /* width: 273px; */
  width: 24%;
  border: 1px solid #262338;
  cursor: pointer;
  /* height: 153px; */
  height: 200px;

  border-radius: 15px;
  position: relative;
  margin-left: 14px;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}