.headerWrapp {
  width: 100%;
}

.headerMain {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.title {
  font-size: 48px;
  font-family: 'Poppins';
  color: white;
}

.inputWrapp {
  position: relative;
}

.input {
  width: 476px;
  height: 40px;
  border-radius: 10px;
  background-color: #262338;
  padding-left: 45px;
  color: #a0a3bd;
  outline: none;
  border: 1px solid #262338;
}

.icon {
  position: absolute;
  top: 0px;
  left: 5px;
  width: 40px;
  height: 38px;
}
