.video__Wrapp{
    width:100vw;
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    position: absolute;
    top:0;
    left:0;
    background-color: #14142B;
    z-index: 9999;
    
}

.video{
    width: 50vw;
    height: 499px;
    border: 2px solid white;
    border-radius: 10px;
    padding: 15px;
}