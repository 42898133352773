.addWrapp {
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: flex-end;
}

.btn {
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ca024f;
  color: white;
  border: 1px solid #ca024f;
  border-radius: 10px;
}

.img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
.para {
  padding: 0;
  margin: 0;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
