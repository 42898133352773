.tableWrapp {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tableMain {
  width: 95%;
  margin-top: 17px;
  font-family: 'Poppins';
}

/* TABLE ITEM */
.tableItem {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  font-family: 'Poppins';
}

.renderImg {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 4px;
  position: relative;
}

.tableItem > div {
  width: 11.5%;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 13px;
  text-align: left;
  color: #a0a3bd;
}

.tableItem > div:last-child {
  width: 8%;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.imageBox {
  width: 100%;
  height: 80px;
  max-width: 140px;
  border-radius: 4px;
  background-color: #262338;
  border: 1px solid #a0a3bd;
  color: #a0a3bd;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* TABLE MENU DOTS */
.menuDotsWrapp {
  position: absolute;
  left: 0%;
  top: 64%;
  background: #a996ff;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  z-index: 10;
}
.closeOverlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: transparent;
}

.listMenu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 150px;
  border-radius: 5px;
  z-index: 10;
}

.listItem {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
}

.listItem:hover {
  background: #2c1a7e;
}

.newlabel {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 5px;
  left: 5px;
  background-color: rgb(71, 0, 171);
  color: rgb(191, 190, 252);
  border: 2px solid rgb(191, 190, 252);
  border-radius: 15px;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
