.mainQuestionWrapp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.boxWrapp {
  width: 586px;
}
